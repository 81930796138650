<template>
  <v-app :style="{ backgroundImage: 'url(assets/fondo.jpg)' }" style="background-position: center center;  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;">
      <router-view/>
      <CuadroDeDialogo  />
      <v-overlay :value="$estadoApp.cargandoOverlay">
        <v-progress-circular
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
  </v-app>
</template>

<script>
import CuadroDeDialogo from './components/CuadroDeDialogo';
export default {
  name: 'App',
  components: { CuadroDeDialogo },
  data: () => ({

  }),
  methods:{

  },
  mounted() {





  },
  created(){

  },

};
</script>
<style>
* {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

body{

  height: 100%;
  overflow-y: hidden;
}


</style>
