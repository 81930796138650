<!--
  Instrucciones:
  ==============

  Incluir el componente en el template de la página raíz, por ejemplo, dentro de "App.vue" (para que pueda ser visible en cualquier momento).
  Sólo se debe incluir una vez en todo el proyecto

    <CuadroDeDialogo />

  En la sección script, incluir lo siguiente:

    import CuadroDeDialogo from '@/components/CuadroDeDialogo.vue';

    export default {
      ...
      components: { CuadroDeDialogo },
      ...
    }

  Finalmente, estos serían unos ejemplos de llamada al cuadro de diálogo desde componentes Vue:

  * Forma simple:

    this.$cuadroDeDialogo.mostrar ('Guardar', '¿Guardar "C:\\prj\\messagebox\\src\\App.vue"?', ['Sí', 'No', 'Cancelar'])
      .then ((boton) => {
        console.log (boton);
      })

  * Forma extendida:

    this.$cuadroDeDialogo.mostrarEx ({
      propsDialog: {
        maxWidth: "35%",
        persistent: true
      },
      propsCard: {
      },
      titulo: {
        icono: {
          nombre: "mdi-comment-question",
          props: {
            color: "#404040",
            large: true
          }
        },
        estilo: "background: #F0F0F0",
        texto: "Título del cuadro de diálogo",
        centrar: false
      },
      lineaHorizontal: false,
      cuerpo: {
        mensajes: [
          {
            estilo: "font-family: monospace",
            texto: "Mensaje a mostrar nº1"
          }
        ],
        centrarMensajes: false,
        props: {
        }
      },
      acciones: {
        botones: [
          {
            identificador: "ACEPTAR",
            texto: "Aceptar",
            props: {
              color: "primary",
              text: true
            }
          },
          {
            identificador: "CANCELAR",
            texto: "Cancelar",
            props: {
              text: true
            }
          }
        ],
        alinear: "D",
        props: {
        }
      }
    })
    .then ((boton) => {
      console.log (boton);
    })

-->

<template>
  <v-dialog v-model="visible" v-bind="propsDialog">
    <v-card v-bind="propsCard">

      <!-- titulo -->
      <v-card-title v-if="titulo" :style="titulo.estilo" class="pt-3 pb-2">
        <v-spacer v-if="titulo.centrar" />
        <v-icon
            v-if="titulo.icono"
            class="ml-n1 mr-1"
            v-bind="titulo.icono.props"
        >
            {{titulo.icono.nombre}}
        </v-icon>
        {{ titulo.texto }}
        <v-spacer v-if="titulo.centrar" />
      </v-card-title>

      <!-- linea separadora titulo/mensaje  -->
      <hr v-if="lineaHorizontal" />

      <!-- mensajes -->
      <v-card-text v-if="cuerpo && cuerpo.mensajes" class="pt-2 pb-1" v-bind="cuerpo.props">
        <span :class="cuerpo.centrarMensajes ? 'text-center' : ''">
          <div
            v-for="(mensaje, i) in cuerpo.mensajes"
            :key="i"
            class="pt-3"
            :style="mensaje.estilo"
            v-bind="mensaje.props"
          >
            {{ mensaje.texto }}
          </div>
        </span>
      </v-card-text>

      <!-- botones -->
      <v-card-actions v-if="acciones" v-bind="acciones.props">
        <v-spacer v-if="['C', 'D', ''].includes (acciones.alinear ? acciones.alinear.toUpperCase() : '')" />
        <v-btn v-for="(boton, i) in acciones.botones" :key="i" v-bind="boton.props" @click="botonClick (boton)">
          {{ boton.texto }}
        </v-btn>
        <v-spacer v-if="['C', 'I'].includes (acciones.alinear ? acciones.alinear.toUpperCase() : '')" />
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

export default {
  data() {
    this.botonClicado = null;
    this.promesaResolve = null;

    return {
      propsDialog: null,
      propsCard: null,
      titulo: null,
      lineaHorizontal: null,
      cuerpo: null,
      acciones: null,
      visible: false,
    };
  },

  methods: {
    mostrarEx (opciones) {
      this.propsDialog = opciones.propsDialog;
      this.propsCard = opciones.propsCard;
      this.titulo = opciones.titulo;
      this.lineaHorizontal = opciones.lineaHorizontal;
      this.cuerpo = opciones.cuerpo;
      this.acciones = opciones.acciones;
      this.visible = true;

      if (typeof this.cuerpo.mensajes === 'string') {
        let aux = this.cuerpo.mensajes.split ("<br>");
        this.cuerpo.mensajes = [];
        for (let i = 0; i < aux.length; i++)
          this.cuerpo.mensajes.push ({ texto: aux[i] });
      }

      this.botonClicado = null;
      return new Promise ((resolve) => {
        this.promesaResolve = resolve;
      });
    },

    mostrar (titulo, mensaje, arrayBotones) {
      let opciones = {
        propsDialog: {
          width: Math.trunc(mensaje.length * 7)+"px",
          maxWidth: "80vw",
          persistent: true
        },
        titulo: {
          texto: titulo
        },
        cuerpo: {
          mensajes: mensaje
        },
        acciones: {
          botones: []
        }
      };
      for (let i = 0; i < arrayBotones.length; i++) {
        opciones.acciones.botones.push ({
          texto: arrayBotones [i],
          props: {
            outlined: true,
            text: true
          }
        });
      }
      return this.mostrarEx (opciones);
    },

    botonClick (boton) {
      this.botonClicado = boton;
      this.visible = false;
    },
  },

  watch: {
    visible() {
      if (!this.visible) { // al cerrarse la ventana, finalizamos la promesa indicando el boton pulsado. Si se cancela el dialogo, nos inventamos un clic en un boton "__CUADRO_DE_DIALOGO_CANCELADO__"
        if (this.botonClicado) {
          this.promesaResolve (
            JSON.parse (JSON.stringify (this.botonClicado))
          );
        } else {
          this.promesaResolve ({
            identificador: "__CUADRO_DE_DIALOGO_CANCELADO__",
            texto: ""
          });
        }
      }
    }
  },

  created() {
    // Hacemos posible el acceso al componente de forma global
    Vue.prototype.$cuadroDeDialogo = this;
  }
};
</script>

<style>
</style>