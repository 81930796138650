import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/HomeView.vue'
import appLocalStorage from "../appLocalStorage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/olvidoContrasena',
    name: 'OlvidasteLaContraseña',
    component: () => import('@/views/OlvidoContrasena.vue'),
    props: true
  },
  {
    path: '/recuperarContrasena',
    name: 'RecuperarContrasena',
    component: () => import('@/views/RecuperarContrasena.vue'),
  },
  {
    path: '/inicio',
    name: 'inicio',
    component: () => import('@/views/Inicio.vue'),
    meta: { requiresAuth: true }
  },
]

const router = new VueRouter({
  routes,
  base: '/',
  mode: 'history'
})


router.beforeEach((to, from, next) => {

  let acceso = false

  if (to.matched.some(route => route.meta.requiresAuth)) {
    if (appLocalStorage.get('accessToken')) {
      acceso = true;
    }
  } else if (to.name == 'Home' || to.name == 'OlvidasteLaContraseña' || to.name == 'RecuperarContrasena') {
    acceso = true
  }

  if (to.name == 'Home' && appLocalStorage.get('accessToken') != null) {
    next({ path: '/inicio' })
  }


  if (!acceso) {
    next({ path: '/' })
  } else {
    next()
  }
})
export default router
