<template>
  <v-main class="fondo" >
    <!-- <img :src="'assets/fondo.jpg'"  style="position: absolute;height: 100vh;" /> -->

    <v-container fill-height >

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="mt-10"
            elevation="0"
            fab
            color="transparent"
            absolute
            top
            right
            v-bind="attrs"
            v-on="on"
            ><i :class="['', 'em', idiomaPorDefecto]"></i>
          </v-btn>
        </template>
        <v-list>
          <v-list-item  v-for="(item, index) in idiomasDisponibles" :key="index">
            <v-list-item-title  @click="cambiarIdioma(item.nom)"
              ><i :class="['', 'em', item.ico]"></i
            ></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-row align="center" justify="center" no-gutters >
        <v-card
          width="400"
          elevation="0"
          class="py-6 px-10 mr-4 text-center"
          style="margin-top: -20px; min-width: 300px"
          :disabled="bloquearFormulario"
        >
          <v-card-text>
           <v-row class="justify-center"  >
             <v-col cols="12" class="justify-center"> <img :src="'assets/logo.png'"  style="max-height: 80px" /></v-col>
           </v-row>
           <p class="mt-2" style="font-weight: 900;font-size: large;">{{$t('tituloGestionReservas')}} <br>
            <span style="font-weight: 400;font-size: small;">{{ $estadoApp.version }}</span></p>

            <div
              class="mt-8 text-h6"
              v-if="!permitirContrasena"
              style="color: #31708f"
            >
              <!-- {{ $t("inicioSesionTitulo") }} -->
            </div>
            <div
              class="mt-8 text-h6"
              v-if="permitirContrasena"
              style="color: #31708f"
            >
              <!-- {{ $t("escribirContrasena") }} -->
            </div>
            <v-row no-gutters class="mt-5">
              <transition
                name="slide-up"
                mode="in-out"
                @after-leave="permitirCorreo = true"
              >
                <v-col align="left" v-if="permitirContrasena" class="mb-4">
                  <v-btn icon small @click="volver" class="mr-1">
                    <v-icon small>mdi-arrow-left</v-icon>
                  </v-btn>
                  <span style="text-align: left">{{ email }}</span>
                </v-col>
              </transition>
              <v-col cols="12">
                <transition
                  name="slide-down"
                  mode="in-out"
                  @after-leave="permitirContrasena = true"
                >
                  <v-text-field
                    key="correo"
                    :label="$t('correo')"
                    v-if="permitirCorreo"
                    dense
                    ref="inputCorreoElectronico"
                    prepend-inner-icon="mdi-email"
                    :rules="[rulesMail.required]"
                    v-model="email"
                    @keypress.enter="obtenerInformacionEntidad"
                    type="email"
                    spellcheck="false"
                  ></v-text-field>
                </transition>
                <transition
                  name="slide-up"
                  mode="in-out"
                  @after-leave="permitirCorreo = true"
                >
                  <v-text-field
                    :label="$t('contra')"
                    key="contra"
                    v-show="permitirContrasena"
                    ref="contrasena"
                    autofocus
                    dense
                    prepend-inner-icon="mdi-account-key"
                    :rules="[rules.required]"
                    v-model="password"
                    :type="mostrarPassword ? 'text' : 'password'"
                    spellcheck="false"
                    :append-icon="mostrarPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="mostrarPassword = !mostrarPassword"
                    @keypress.enter="ingresar()"
                  ></v-text-field> </transition
                ><br v-if="permitirContrasena" />
                <transition
                  name="slide-down"
                  mode="in-out"
                  @after-leave="permitirCorreo = true"
                >
                  <a
                    v-if="permitirContrasena"
                    @click="recuperar"
                    class="mt-1"
                  >
                    {{ $t("olvidasteContrasena") }}
                  </a>
                </transition>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              v-if="permitirContrasena"
              class="mt-2"
              block
              large
              :loading="realizandoLogin"
              color="primary"
              elevation="2"
              @click="ingresar"
              :disabled="!datosRellenosContrasena"
              >{{ $t("ingresar") }}</v-btn
            >
            <v-btn
              v-if="permitirCorreo"
              class="mt-2 square td-speedy"
              block
              large
              :loading="cargando"
              color="primary"
              elevation="2"
              @click="obtenerInformacionEntidad"
              :disabled="!datosRellenosCorreo"
              >{{ $t("siguiente")
              }}<v-icon class="ml-2">mdi-arrow-right-bold</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>

        <v-dialog v-model="cuadroEmergente.mostrar" max-width="490">
          <v-card>
            <v-card-title class="headline">{{
              cuadroEmergente.titulo
            }}</v-card-title>
            <v-card-text>{{ cuadroEmergente.texto }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary darken-1"
                text
                @click="cuadroEmergente.mostrar = false"
                >{{ $t("aceptar") }}</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
  </v-main>
</template>

<script >
import Vue from "vue";
import api from "../api";
import i18n from "@/plugins/i18n";
import appLocalStorage from "../appLocalStorage";
import { Preferences } from '@capacitor/preferences';
export default Vue.extend({
  name: "HomeView",

  components: {},
  data() {
    return {
      ver: false,
      rules: {
        required: (value) => !!value || this.$t('requerido'),
      },
      rulesMail:{
        required:(value)=> !value || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) || this.$t('correoElectronicoNoValido')
      },
      email: "",
      permitirCorreo: true,
      permitirContrasena: false,
      shown: false,
      cargando: false,
      password: "",
      guid: "",
      mostrarPassword: false,
      idiomaSeleccionado: "",
      idiomasDisponibles: [],

      realizandoLogin: false,
      bloquearFormulario: false,
      idiomaSeleccionado: "",
      iconoSelect: "mdi-flag",
      idiomaPorDefecto: "em-flag-ea",
      idiomasDisponibles: [
        {
          ico: "em-flag-ea",
          nom: "Español",
        },
        {
          ico: "em-gb",
          nom: "Ingles",
        },
      ],
      cuadroEmergente: {
        mostrar: false,
        titulo: "",
        texto: "",
      },
    };
  },
  beforeMount() {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
    });
  },
  computed: {
    datosRellenosCorreo() {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
          return true;
      } else {
          return false;
      }
    },
    datosRellenosContrasena() {
      return this.password != "" && this.password.length >= 8;
    },
  },
  created(){
    this.asignarIdiomaApp();

  },
  methods: {

    recuperar(){

      this.$estadoApp.emailOlvidado = this.email;
      this.$router.push({path:'/olvidoContrasena'})
    },

    //Asignamos idioma por defecto
    asignarIdiomaApp(){
      Preferences.get({key:"idioma_caja_movil"})
        .then((res)=>{
          if(res['value']){
            if(res['value'] == 'es'){
              this.idiomaPorDefecto = "em-flag-ea";
            }else{
              this.idiomaPorDefecto = "em-gb";
            }
            i18n.locale = res['value']
          }else{
            Preferences.set({
              key: "idioma_caja_movil",
              value: "es",
            }).then(() => {
              this.idiomaPorDefecto = "em-flag-ea";
              i18n.locale = "es";
            });
          }
        })
    },
    volver() {

      this.permitirContrasena = false;
      // this.permitirCorreo = true;
    },
    olvideContrasena() {
      this.$router.push("/olvidoContrasena");
    },
    //Obtenemos la informacion de la entidad mediante el correo indicado
    obtenerInformacionEntidad() {
      this.cargando = true;
      api
        .obtenerInformacionEntidad(this.email)
        .then((res) => {
          if (res.data.error) {
            this.cuadroEmergente.mostrar = true;
            this.cuadroEmergente.titulo = this.$t('correoIncorrecto');
            this.cuadroEmergente.texto =
              this.$t('correoIncorrecto2');
          } else if (res.data.usuario == "Autorizado") {

            this.guid = res.data.guid;
            // this.permitirContrasena = true;
            this.permitirCorreo = false;

          } else if (res.data.usuario == "Correo") {
            this.cuadroEmergente.mostrar = true;
            this.cuadroEmergente.titulo = this.$t('registroUsuario');
            this.cuadroEmergente.texto =
             this.$t('registroUsuario2');
          }
        })
        .finally(() => {
          this.cargando = false;
        });
    },
    dismissPrompt() {
      this.shown = false;
    },

    async installPWA() {
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt(); // Hide the prompt once the user's clicked
        if (choice.outcome === "accepted") {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      });
    },
    cambiarIdioma(idioma) {

      if (idioma == "Español") {

          Preferences.set({
            key: "idioma_caja_movil",
            value: "es",
          }).then(() => {
            this.idiomaPorDefecto = "em-flag-ea";
            i18n.locale = "es";
          });
          // this.idiomaPorDefecto = "em-flag-ea";
          //  i18n.locale = "es";
        } else {
          Preferences.set({
            key: "idioma_caja_movil",
            value: "en",
          }).then(() => {
            this.idiomaPorDefecto = "em-gb";
            i18n.locale = "en";
          });
          // this.idiomaPorDefecto = "em-flag-england";
          // i18n.locale = "en";
        }
    },
    obtenerIdiomas() {
      api.obtenerIdiomas().then((res) => {
        this.idiomasDisponibles = res.data.idiomas;
      });
    },
    ingresar() {
      this.realizandoLogin = true;
      api
        .iniciarSesion(this.guid, this.password)
        .then((res) => {
          if (res.status == 200) {

            appLocalStorage.set("accessToken", res.data.accessToken);
            appLocalStorage.set(
              "correo",
              res.data.operador["correoElectronico"]
            );

            this.$router.push("/inicio");
          }
        })
        .catch((e) => {
          this.cuadroEmergente.mostrar = true;
          this.cuadroEmergente.titulo = this.$t("errorInicioSesion");
          this.cuadroEmergente.texto = this.$t("errorInicioSesionOperador");
        })
        .finally(() => {
          this.realizandoLogin = false;
        });
    },
  },
});
</script>
<style>
.slide-up-leave-active {
  transition: all 0.7s ease-out;
}

.slide-up-enter-active {
  transition: all 0.7s ease-out;
}

.slide-up-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}

.slide-up-leave-to {
  opacity: 0;
  transform: translateX(50px);
}

.slide-down-leave-active {
  transition: all 0.7s ease-out;
}

.slide-down-enter-active {
  transition: all 0.25s ease-out;
}

.slide-down-enter-from {
  opacity: 0;
  transform: translateX(-30px);
}

.slide-down-leave-to {
  opacity: 0;
  transform: translateX(-50px);
}


</style>